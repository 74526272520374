$primary-color: #0C5FFE;
$card-label-text: #B5D7FE;
$text-dark: #01080F;
$text-secondary: #686F81;
$text-link: #5F7DB6;
$placeholder-color: #BBBEC6;
$text-muted: #BABABA;
$line: #aaa;

$env-safe-bottom: max(env(safe-area-inset-bottom), 24px);

@mixin btn($size: 'lg'){
    background: linear-gradient(213deg, #198BFE 0%, #0C5FFE 100%);
    appearance: none;
    outline: none;
    width: 100%;
    display: block;
    border: none;
    color: #FFFFFF;
    @if($size == 'sm') {
        height: 22px;
        border-radius: 4px;
        font-size: 11px;
        line-height: 15px;
        padding: 4px 11px;
        position: relative;
        &::after{
            position: absolute;
            top: -50%;
            right: -50%;
            bottom: -50%;
            left: -50%;
            content: '';
        }
    }
    @else{
        height: 49px;
        border-radius: 8px;
        font-size: 18px;
        line-height: 25px;
        padding: 12px 20px;
    }
}

@mixin text-overflow{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


@mixin hairline-bottom($left: 0, $right: 0, $color: #DFE7F2){
    position: relative;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: $left;
        right: $right;
        height: 1px;
        background-color: $color;
        transform: scaleY(.5);
    }
}
.steps {
  .title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #01080F;
    line-height: 23px;
    margin-top: 26px;
    &::before,&::after{
      display: block;
      content: '';
      width: 42px;
      height: 2px;
      background: linear-gradient(-90deg, rgba(238,238,238,0) 0%, #E7EDF2 100%);
      margin: 0 10px;
    }
    &::before{
      background: linear-gradient(-90deg, #E7EDF2 0%, rgba(238,238,238,0) 100%);
    }
  }
  .body{
    background: #F2F7FF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 20px;
    padding: 17px 0 13px;
    &.hide_line{
      .item::after{display: none;}
    }
    .item{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      font-size: 13px;
      color: $text-dark;
      line-height: 19px;
      position: relative;
      &:last-child::after{
        display: none;
      }
      &::after{
        position: absolute;
        content: '';
        width: 78px;
        height: 4px;
        background: url('~@/assets/imgs/image-step-line@2x.png') no-repeat;
        background-size: contain;
        top: 10px;
        left: calc(100% / 2 + 18px);
      }

      img{
        display: block;
        width: 22px;
        height: 24px;
        margin-bottom: 8px;
      }
    }
  }
}