$primary-color: #0C5FFE;
$card-label-text: #B5D7FE;
$text-dark: #01080F;
$text-secondary: #686F81;
$text-link: #5F7DB6;
$placeholder-color: #BBBEC6;
$text-muted: #BABABA;
$line: #aaa;

$env-safe-bottom: max(env(safe-area-inset-bottom), 24px);

@mixin btn($size: 'lg'){
    background: linear-gradient(213deg, #198BFE 0%, #0C5FFE 100%);
    appearance: none;
    outline: none;
    width: 100%;
    display: block;
    border: none;
    color: #FFFFFF;
    @if($size == 'sm') {
        height: 22px;
        border-radius: 4px;
        font-size: 11px;
        line-height: 15px;
        padding: 4px 11px;
        position: relative;
        &::after{
            position: absolute;
            top: -50%;
            right: -50%;
            bottom: -50%;
            left: -50%;
            content: '';
        }
    }
    @else{
        height: 49px;
        border-radius: 8px;
        font-size: 18px;
        line-height: 25px;
        padding: 12px 20px;
    }
}

@mixin text-overflow{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


@mixin hairline-bottom($left: 0, $right: 0, $color: #DFE7F2){
    position: relative;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: $left;
        right: $right;
        height: 1px;
        background-color: $color;
        transform: scaleY(.5);
    }
}
.cityPicker{
    :global{
        --adm-color-primary: var(--primary-color, #1677ff);
    }
}