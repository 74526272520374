$primary-color: #0C5FFE;
$card-label-text: #B5D7FE;
$text-dark: #01080F;
$text-secondary: #686F81;
$text-link: #5F7DB6;
$placeholder-color: #BBBEC6;
$text-muted: #BABABA;
$line: #aaa;

$env-safe-bottom: max(env(safe-area-inset-bottom), 24px);

@mixin btn($size: 'lg'){
    background: linear-gradient(213deg, #198BFE 0%, #0C5FFE 100%);
    appearance: none;
    outline: none;
    width: 100%;
    display: block;
    border: none;
    color: #FFFFFF;
    @if($size == 'sm') {
        height: 22px;
        border-radius: 4px;
        font-size: 11px;
        line-height: 15px;
        padding: 4px 11px;
        position: relative;
        &::after{
            position: absolute;
            top: -50%;
            right: -50%;
            bottom: -50%;
            left: -50%;
            content: '';
        }
    }
    @else{
        height: 49px;
        border-radius: 8px;
        font-size: 18px;
        line-height: 25px;
        padding: 12px 20px;
    }
}

@mixin text-overflow{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


@mixin hairline-bottom($left: 0, $right: 0, $color: #DFE7F2){
    position: relative;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: $left;
        right: $right;
        height: 1px;
        background-color: $color;
        transform: scaleY(.5);
    }
}
.progress {
    padding: 8px 20px 0;
    background-color: #F6F6F6;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;

    h1 {
        font-size: 16px;
        font-weight: 700;
        color: $text-dark;
        line-height: 23px;
        margin-bottom: 6px;

        span {
            color: $primary-color
        }
    }
}
.main_wrapper{
    padding-top: 54px !important;
}

.p2 {
    &_protocol{
        padding: 49px 0 22px;
    }
}
.footer_inner{
    display: flex;
    align-items: center;
    margin-top: 22px;
}

.form {
    --border-inner: none !important;
    --border-top: none !important;
    --border-bottom: none !important;
    --prefix-width: 6.8em !important;
    ---border-inner: var(--border-inner);
    ---border-top: var(--border-top);
    ---border-bottom: var(--border-bottom);
    ---prefix-width: var(--prefix-width);

    :global {
        .adm-list-body {
            background-color: transparent;
        }

        .adm-form-item-label {
            font-size: 16px;
            color: $text-secondary;
            line-height: 23px;
        }

        .adm-input-element {
            text-align: right;
            font-size: 16px;
            color: $text-dark;
            line-height: 23px;

            &::placeholder {
                color: $placeholder-color
            }
        }

        .adm-list-item {
            padding-left: 0;
            @include hairline-bottom();
            background-color: transparent;

            &.adm-form-item-has-error {
                @include hairline-bottom(0, 0, #EA4D44);
                margin-bottom: 20px;
            }

            .adm-list-item-content {
                padding-right: 0;
                position: relative;
            }

            .adm-form-item-feedback-error {
                position: absolute;
                left: 0;
                bottom: -18px;
                font-size: 10px;
                font-weight: 700;
                color: #EA4D44;
                line-height: 14px;
            }

            &:last-child::after {
                display: none;
            }
        }
    }

    &_header {
        position: relative;
        padding: 19px 0 10px 25px;
        font-weight: bold;

        img {
            position: absolute;
            left: 0;
            width: 22px;
            height: 22px;
        }

        h1 {
            font-size: 16px;
            color: $text-dark;
            line-height: 23px;
            white-space: nowrap;
        }

        p {
            font-size: 13px;
            color: #6A86BB;
            line-height: 19px;
            white-space: nowrap;
            margin-top: 3px;
        }
    }

    &_card {
        margin-bottom: 10px;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 0 20px;

        .arrow {
            width: 9px;
            height: 9px;
            transition: all ease-in-out .3s;
            &.is_expand{
                transform: rotate(180deg);
            }
        }

        &.is_basic {
            background: linear-gradient(180deg, #CEE1FF 0%, #FFFFFF 30%);
        }

        .encourage {
            margin: 10px 0;
            height: 36px;
            background: url(~@/assets/imgs/incentive-bg@2x.png) no-repeat;
            background-size: cover;
            font-size: 13px;
            color: $text-secondary;
            line-height: 30px;
            text-align: center;
            display: flex;
            justify-content: center;
            &::before,&::after{
                content: '';
                display: block;
                width: 13px;
                height: 13px;
                margin: 0 3px;
                background-size: contain !important;
                margin-top: 5px;
            }
            &::before{
                background: url(~@/assets/imgs/icon-dot-left.png) no-repeat;
            }
            &::after{
                margin-left: 0;
                background: url(~@/assets/imgs/icon-dot-right.png) no-repeat;
            }

            span {
                color: $primary-color
            }
        }

    }
}