$primary-color: #0C5FFE;
$card-label-text: #B5D7FE;
$text-dark: #01080F;
$text-secondary: #686F81;
$text-link: #5F7DB6;
$placeholder-color: #BBBEC6;
$text-muted: #BABABA;
$line: #aaa;

$env-safe-bottom: max(env(safe-area-inset-bottom), 24px);

@mixin btn($size: 'lg'){
    background: linear-gradient(213deg, #198BFE 0%, #0C5FFE 100%);
    appearance: none;
    outline: none;
    width: 100%;
    display: block;
    border: none;
    color: #FFFFFF;
    @if($size == 'sm') {
        height: 22px;
        border-radius: 4px;
        font-size: 11px;
        line-height: 15px;
        padding: 4px 11px;
        position: relative;
        &::after{
            position: absolute;
            top: -50%;
            right: -50%;
            bottom: -50%;
            left: -50%;
            content: '';
        }
    }
    @else{
        height: 49px;
        border-radius: 8px;
        font-size: 18px;
        line-height: 25px;
        padding: 12px 20px;
    }
}

@mixin text-overflow{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


@mixin hairline-bottom($left: 0, $right: 0, $color: #DFE7F2){
    position: relative;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: $left;
        right: $right;
        height: 1px;
        background-color: $color;
        transform: scaleY(.5);
    }
}
.product_item {
	border-radius: 10px;
	padding: 20px;
  background-color: #fff;
  margin-top: 10px;
  position: relative;
	.head {
		display: flex;
		align-items: center;
		font-size: 15px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #01080f;
		line-height: 21px;
    @include text-overflow;
		img {
			display: block;
			width: 15px;
			height: 15px;
			margin-right: 8px;
		}
	}
  .inner{
    display: flex;
    align-items: flex-end;
    margin-top: 12px;
    .item{
      margin-right: 15px;
      &:first-child{
        margin-right: 0;
      }
      h1{
        font-family: 'DIN Alternate';
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        color: $text-dark;
        &.amount{
          color: #EA4D44;
        }
      }
      p{
        margin-top: 4px;
        font-size: 12px;
        color: $text-secondary;
        line-height: 17px;
        white-space: nowrap;
      }
    }
    .info{
      margin-left: 15px;
      p{
        font-size: 12px;
        color: $text-secondary;
        line-height: 17px;
        margin: 3px 0 0;
        white-space: nowrap;
        &:first-child{
          margin-top: 0;
        }
        span{
          font-size: 14px;
          font-weight: 700;
          color: $text-dark;
          font-family: 'DIN Alternate';
        }
      }
    }
  }

  .btn{
    margin-top: 15px;
    @include btn('sm')
  }

  .float_center{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  }
}
