$primary-color: #0C5FFE;
$card-label-text: #B5D7FE;
$text-dark: #01080F;
$text-secondary: #686F81;
$text-link: #5F7DB6;
$placeholder-color: #BBBEC6;
$text-muted: #BABABA;
$line: #aaa;

$env-safe-bottom: max(env(safe-area-inset-bottom), 24px);

@mixin btn($size: 'lg'){
    background: linear-gradient(213deg, #198BFE 0%, #0C5FFE 100%);
    appearance: none;
    outline: none;
    width: 100%;
    display: block;
    border: none;
    color: #FFFFFF;
    @if($size == 'sm') {
        height: 22px;
        border-radius: 4px;
        font-size: 11px;
        line-height: 15px;
        padding: 4px 11px;
        position: relative;
        &::after{
            position: absolute;
            top: -50%;
            right: -50%;
            bottom: -50%;
            left: -50%;
            content: '';
        }
    }
    @else{
        height: 49px;
        border-radius: 8px;
        font-size: 18px;
        line-height: 25px;
        padding: 12px 20px;
    }
}

@mixin text-overflow{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


@mixin hairline-bottom($left: 0, $right: 0, $color: #DFE7F2){
    position: relative;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: $left;
        right: $right;
        height: 1px;
        background-color: $color;
        transform: scaleY(.5);
    }
}
.product_main {
    margin-top: 12px;
    background: linear-gradient(180deg, #CEE1FF 0%, #FFFFFF 30%);
    border-radius: 8px;
    padding: 18px;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    .head {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: $text-dark;
        line-height: 23px;
        width: 100%;

        img {
            display: block;
            width: 22px;
            height: 22px;
            margin-right: 3px;
            border-radius: 22px;
        }
    }

    .label {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: $text-secondary;
        line-height: 19px;
        margin-top: 16px;
    }

    .amount {
        margin: 6px auto 4px;
        display: inline-block;
        background: url('~@/assets/imgs/loan-amount-bg.png') repeat-x;
        background-size: 100% 50px;
        background-position: left 10px;
        font-size: 50px;
        font-weight: bold;
        color: $text-dark;
        line-height: 61px;
        height: 61px;
    }

    .rate {
        font-size: 14px;
        color: $text-secondary;
        line-height: 20px;
        text-align: center;
        margin-bottom: 18px;
    }

    .btn_wrapper {
        width: 100%;
        position: relative;
        overflow: hidden;
        padding-top: 9px;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 78px;
            height: 49px;
            background: url(~@/assets/imgs/flash-bg@2x.png) no-repeat;
            background-size: contain;
            animation: move 2s ease-out infinite;
        }

        .btn {
            @include btn;
        }
    }

    .help {
        margin-top: 8px;
        font-size: 12px;
        text-align: center;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: $text-secondary;
        line-height: 17px;
    }

    .today_count {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #723903;
        line-height: 17px;
        width: 120px;
        height: 40px;
        background: url(~@/assets/imgs/label-bg@2x.png) no-repeat;
        background-size: contain;
        line-height: 30px;
        text-align: center;
    }

    .matched {
        position: absolute;
        width: 83px;
        height: 40px;
        top: 0;
        right: 0;
    }

    .empty {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            display: block;
            width: 50px;
            height: 50px;
            margin: 22px 0 6px;
        }

        h1 {
            font-size: 16px;
            font-weight: bold;
            color: $text-dark;
            line-height: 23px;
            margin-bottom: 29px;
        }
    }
}

@keyframes move {
    0% {
        left: 0;
    }

    100% {
        left: 100%;
    }
}